module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-starberry-lomondgroup/gatsby-browser.js'),
      options: {"plugins":[],"news_insight":"about-lomond-group/latest-property-news","our_brands":"our-brands","teams":"about-lomond-group/our-people"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/static/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"efadbaaf22d48f52b3a5a5dd0f611255"},
    }]
